import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";

export default class AppBody extends Component {

    handleCopy = () => {
        let textField = document.createElement('textarea')
        textField.innerText = this.props.result.resultUrl;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    render() {
        return (
            <Modal keyboard={false}
                   backdrop="static"
                   show={this.props.showModel}
                   onHide={this.props.handleClose}
                   animation={true}
                   centered>

                <Modal.Header closeButton>
                    <Modal.Title>Your Shorten URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        URL : <a rel="noopener noreferrer" href={this.props.result.resultUrl}
                                 target="_blank"> {this.props.result.resultUrl} </a>
                    </p>
                    <p>
                        Password : {this.props.result.password}
                    </p>
                    <br/>
                    <small>Note : Above URL is been sent to your Email also.</small>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleCopy}>
                        Copy URL
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}