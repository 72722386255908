import React, {Component} from "react";
import UrlShorterForm from './UrlShorterForm'
import AlertInfo from "./AlertInfo";
import ModalDialog from "./ModalDialog";

export default class AppBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            errors: {
                email: "",
                url: "",
                valid_till: ""
            },
            isError: false,
            result: {
                resultUrl: "",
                password: ""
            }
        };
    }

    alterState = (key, value) => {
        let state = this.state;
        state[key] = value;
        this.setState({...state})
    }

    handleClose = () => {
        this.alterState("showModel", false)
    }

    handleShow = () => {
        this.alterState("showModel", true)
    }

    render() {
        return (
            <div className="row">
                <div className="col">

                    <UrlShorterForm
                        alterState={this.alterState}
                        errors={this.state.errors}
                    />

                    <AlertInfo
                        isError={this.state.isError}
                        errors={this.state.errors}
                        alterState={this.alterState}
                    />

                    <ModalDialog
                        showModel={this.state.showModel}
                        result={this.state.result}
                        handleClose={this.handleClose}
                    />

                </div>
            </div>
        );
    }
}