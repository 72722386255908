import React, {Component} from "react";
import APIservices from "../services/APIservices";
import {Alert, Button, FormControl, InputGroup, Modal} from "react-bootstrap";

export default class Redirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                code: "",
                password: "",
            },
            passwordModal: true,
            error: {
                show: false,
                message: ""
            }
        };
    }

    componentDidMount() {
        this.alterState('formData', {...this.state.formData, 'code': this.props.match.params.code})
    }

    alterState = (key, value) => {
        let state = this.state;
        state[key] = value.constructor === String ? value.trim() : value;
        this.setState({...state})
    };

    handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = this.state.formData;
        formData[name] = value
        this.alterState('formData', formData)
    }

    submit = async e => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.formData.password.length < 4) {
            this.alterState('error', {
                show: true,
                message: "Please Enter Valid Password. Password Should be more than 4 and less than 10 character"
            })
        } else {
            this.alterState('passwordModal', false);
            const response = await APIservices.redirectURL(this.state.formData.code, {password: this.state.formData.password});
            if (response.data.error === undefined) {
                let url = response.data.URL;
                if (!url.match(/^http?:\/\//i) || !url.match(/^https?:\/\//i)) {
                    url = 'http://' + url;
                }
                window.location.href = url;
            } else {
                this.alterState('passwordModal', true);
                this.alterState('error', {
                    show: true,
                    message: response.data.error
                })
            }
        }
    }

    render() {
        return (
            <div>
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-light" style={{width: "30rem", height: "30rem"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <PasswordModal
                    error={this.state.error}
                    password={this.state.password}
                    show={this.state.passwordModal}
                    handleUserInput={this.handleUserInput}
                    onHide={() => this.setState({...this.state, passwordModal: false})}
                    onSubmit={this.submit}
                />
            </div>
        );
    }
}

function PasswordModal(props) {
    return (
        <Modal
            show={props.show}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Please enter you password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <InputGroup size="lg">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-lg">Password</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        required
                        name="password"
                        maxLength="10"
                        minLength="4"
                        value={props.password}
                        onChange={props.handleUserInput}
                        type="password"
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                    />
                </InputGroup>
                <br/>
                <Alert show={props.error.show} variant="danger">
                    {props.error.message}
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
}