import React, {Component} from "react";
import {Link} from "react-router-dom";

export default class TopNavigation extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to={"/"}>
                    <img src="https://blog.mmtech.dev/publish/69ba55e7-d8af-463c-a9db-12d14184a252"
                         width="250"
                         height="50"
                         className="d-inline-block"
                         alt=""
                         style={{paddingRight: '20px'}}
                    />
                    {process.env.REACT_APP_APPLICATION_NAME}
                </Link>
                {/*<div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/sign-in"}>Login</Link>
                            </li>
                        </ul>
                    </div>*/}
            </nav>
        );
    }
}