import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TopNavigation from './components/TopNavigation'
import AppBody from './components/AppBody'
import Redirect from './components/Redirect'
import {
    Switch,
    Route,
} from "react-router-dom";

function App() {
    return (
        <div>
            <TopNavigation> </TopNavigation>
            <Switch>
                <Route exact path="/:code" component={Redirect}/>
                <Route path="/" component={AppBody}/>
            </Switch>
        </div>
    );
}

export default App;
