import React, {Component} from "react";
import {Button, Form} from 'react-bootstrap';
import APIservices from "../services/APIservices";

const emailRegx = RegExp(/^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\]+)\.([a-zA-Z]{2,5})$/);
const urlRegx = RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/);

export default class UrlShorterForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {
                email: "",
                url: "",
                password: "",
            },
            loader: false
        };
    }

    alterState = (key, value) => {
        let state = this.state;
        state[key] = value;
        this.setState({...state})
    }

    handleCopy = () => {
        let textField = document.createElement('textarea')
        textField.innerText = this.state.resultUrl;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = this.state.formData;
        formData[name] = value.constructor === String ? value.trim() : value;
        this.alterState('formData', formData)
    }

    submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.inputValidation()) {
            return false;
        } else {
            this.alterState('loader', true)
            const response = await APIservices.generateShortURL(this.state.formData);
            this.props.alterState('result', {
                password: response.data.Password,
                resultUrl: process.env.REACT_APP_URL + "/" + response.data.ShortCode
            });
            this.alterState('loader', false)
            this.props.alterState("showModel", true);
        }
    }

    inputValidation = () => {
        let isValid = true;

        let errors = this.props.errors;

        if (this.state.formData.email.length > 0 && emailRegx.test(this.state.formData.email) && this.state.formData.email.length <= 250) {
            errors.email = ""
        } else {
            errors.email = "Invalid Email or Email Length is more than 250"
            isValid = false;
        }

        if (this.state.formData.url.length > 0 && urlRegx.test(this.state.formData.url) && this.state.formData.url.length <= 1000) {
            errors.url = ""
        } else {
            errors.url = "Invalid URL or URL Length is more than 100"
            isValid = false;
        }

        if (this.state.formData.password.length === 0 || (this.state.formData.password.length >= 4 && this.state.formData.password.length <= 10)) {
            errors.password = ""
        } else {
            errors.password = "Max and Max Length of Password is 4-10"
            isValid = false;
        }

        this.props.alterState("errors", errors);
        this.props.alterState("isError", !isValid);
        return isValid;
    }

    render() {
        if (this.state.loader) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-light" style={{width: "30rem", height: "30rem"}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div>

                    <div className="jumbotron jumbotron-fluid">
                        <div className="container">
                            <Form>
                                <Form.Group controlId="email">
                                    <Form.Label>Email address*</Form.Label>
                                    <Form.Control value={this.state.formData.email} name='email' type="email"
                                                  placeholder="Enter email" onChange={this.handleUserInput}/>
                                    <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="url">
                                    <Form.Label>URL*</Form.Label>
                                    <Form.Control value={this.state.formData.url} name="url" type="url"
                                                  placeholder="Enter URL" onChange={this.handleUserInput}/>
                                    <Form.Text className="text-muted">
                                        We'll be encrypted in database.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control value={this.state.formData.password} name="password" type="password"
                                                  placeholder="Enter Password [ Default : 0000 ]"
                                                  onChange={this.handleUserInput}
                                                  maxLength="10"/>
                                    <Form.Text className="text-muted">
                                        We'll be encrypting URL based on password. If Password is lost, all is lost.
                                        Default Password is 0000
                                    </Form.Text>
                                </Form.Group>

                                <Button variant="primary" type="button" onClick={this.submitForm}>
                                    Generate
                                </Button>
                            </Form>

                        </div>
                    </div>
                </div>
            );
        }
    }
}