import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_BASE_API_URL

export default {
    postData: function (url, data) {
        let headers = {
            "Content-Type": "application/json"
        };
        return axios.post(baseURL + url, data, {headers: headers});
    },
    getData: function (url, data) {
        return axios.get(baseURL + url, {params: data});
    }
}