import React, {Component} from "react";
import {Alert, Button, ListGroup} from "react-bootstrap";

export default class AppBody extends Component {
    render() {
        return (
            <Alert show={this.props.isError} variant="danger">
                <Alert.Heading>Validation Error</Alert.Heading>
                <ListGroup>
                    {
                        Object.keys(this.props.errors).map((item, index) => {
                            if (this.props.errors[item].length !== 0) {
                                return (
                                    <ListGroup.Item variant="danger"
                                                    key={index}> {this.props.errors[item]} </ListGroup.Item>
                                )
                            }
                        })
                    }
                </ListGroup>
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => this.props.alterState("isError", false)} variant="outline-danger">
                        Close
                    </Button>
                </div>
            </Alert>
        );
    }
}