import API from "./API";

class APIservices {
    generateShortURL = async (data) => {
        return await API.postData('/generate-short-url', data);
    }
    redirectURL = async (code, data) => {
        return await API.getData('/short/' + code, data);
    }
}

export default new APIservices();